<template>
  <div class="container-main">
    <div class="title-vdo-01">Terms & Conditions</div>

    <div class="abt-us">
      <p style="text-align: justify;">
        You understand and acknowledge that all copyright, service marks,
        trademarks, trade and intellectual property names of Momagic Bangladesh
        limited (“Momagic”), including but not limited to Momagic, power tools,
        ask star, are the exclusive property of Momagic, and you shall not use
        any of the marks without Momagic’s prior written approval.
      </p>
      <p style="text-align: justify;">
        The content of this website is produced by Momagic, or under its
        instructions all intellectual property rights in respect of the content
        vest with Momagic, you may not therefore copy or reproduce the same
        without Momagic’s prior written permission.
      </p>
      <p style="text-align: justify;">
        Momagic does not represent or warrant that the content of this website
        is error free or suitable for any particular purpose. Momagic
        accordingly shall not be liable to any person who may rely on any such
        content and/or who may suffer any kind of loss due to this reliance.
        Momagic, shall also not be liable in respect of any other information
        which may be accessed through this website.
      </p>
      <p style="text-align: justify;">
        Certain information and/ services mentioned herein may not be accessible
        to you if your PC does not support the relevant technology.
      </p>
      <p style="text-align: justify;">
        Momagic, reserves the right to discontinue or suspend this website at
        any time without notice, and also to amend these terms and conditions at
        its own discretion without prior notice.
      </p>
      <p style="text-align: justify;">
        We discourage to provide any confidential / proprietary information. but
        in some areas of Momagic, we may need your information to fulfill the
        service requested. In such cases, any information of the visitors /
        users of the portal will be treated in accordance with Momagic privacy
        policy.
      </p>
      <p style="text-align: justify;">
        These terms and condition shall be governed and interpreted in
        accordance with the laws of Bangladesh and you consent to the
        non-exclusive jurisdiction of Bangladesh courts.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermCondition"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-main {
  display: block;
  margin: 0 auto;
}
.title-vdo-01 {
  padding: 15px 15px 10px 15px;
  font-family: inherit;
  font-weight: 600;
  font-size: 24px;
  color: aliceblue;
  line-height: 1.2;
  /* background: #232f3e; */
}
.abt-us {
  margin: 5px 15px 25px 15px;
  display: block;
  padding-bottom: 40px;
}

.abt-us p {
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  color: aliceblue;
  line-height: 2;
  margin-bottom: 20px;
}
</style>
